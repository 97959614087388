import React from "react"
import { card, tile } from "~vanilla/guide-primary.css"
import Markdown from "markdown-to-jsx"
import { Link } from "gatsby"
import { MdLink } from "react-icons/md"
import { H1, H2, H3 } from "@fastly/consistently"
import { Button } from "@fastly/consistently-vanilla"
import T from "~components/translator"
import { Tile } from "@fastly/consistently-vanilla";
import { Arrow } from "@fastly/consistently-icons";
import { ctaButton } from "../vanilla/guide-primary.css";

const DisplayGuidesInPrimary = ( props ) => {
  let { frontmatter, body, sec_cards, primary_orphans, rootPage=false } = props

  let any_content = (sec_cards.length + primary_orphans.length)

  return (<>
    {any_content ?
      make_primary_header_card(frontmatter, rootPage, body)
      : ""
    }

    {sec_cards}

    {primary_orphans}
  </>)
}

export const make_primary_header_card = (frontmatter, rootPage, body) => {
  let card_text = (
    <Markdown>
      {frontmatter['description'] || ""}
    </Markdown>)

  // @TODO: find if we can detect the length of the body somehow, and if its non-blank, auto switch to it
  if (frontmatter?.usebody) {
    card_text = body
  }

  return (<>
    <div id={`_${frontmatter.cat_id}`} className={card["primary"]}>
      {frontmatter['svg']?
        <div className="svg_icon">
          <img data-todo-class='no-shadow' role="img" alt="" src={`/assets/images/icons/${frontmatter['svg']}.svg`} height="70"/>
        </div>
      :""}
      <div className="card-body">
        {rootPage ?
          <H1>{frontmatter.section_name}</H1>
          :
          <H3 as="h2">{frontmatter.section_name}</H3>
        }

        <p className="card-text">
          {card_text}
        </p>
      </div>
    </div>
  </>)
}

export const grouped_guides_to_sec_cards = (pfrontmatter, pdata, sdata, snav=[]) => {
  let sec_cards = []

  //first loop over the pdata guides and split into secondary buckets
  snav.forEach((yaml_branch) => {
    const sec_key = yaml_branch.crumb
    const isUnlisted = (yaml_branch?.unlisted && yaml_branch.unlisted) ?? false

    if (isUnlisted) {
      return
    }

    const secondaryObject = sdata.find((secobj) => {
      return secobj.frontmatter.crumb === sec_key
    })


    let guides_in_sec = pdata?.filter(pthing => pthing.frontmatter.secondary_nav === sec_key)

    let links_in_sec = guides_in_sec?.map((g) => {
      // this should be handled in the query, but just in case
      if(g.frontmatter.unlisted) return false

      let fslug = g.slug.split("/", 2)[1]

      let g_url = pfrontmatter.section_url + fslug + ""

      return (<li key={g.slug}><a href={g_url}>{g.frontmatter.header}</a></li>)
    })

    let { frontmatter: secondaryMeta } = secondaryObject

    /* @TODO, we need to try to yse secondaryObject.body first? and fallback to secondaryMeta.desc */
    let newcard = make_sec_card(
      `_${secondaryMeta.crumb}`,
      secondaryMeta.name,
      secondaryMeta.description,
      links_in_sec
    )

    sec_cards.push(newcard)
  })

  return sec_cards
}

const make_sec_card = (skey, card_title, card_text, guides_in_sec_links) => {
  // @NOTE skey has a leading underscore
  return (
    <Tile className={tile}>
      <div className={card["secondary"]} id={skey}>
        <div className="card-body">
          <div className="card-title">{card_title}</div>&nbsp;
          <a className="permalink" href={`#${skey}`} title="Permalink to this section"><MdLink /></a>
          <p className="card-text">{card_text}</p>
          <ul className="two_column">
            {guides_in_sec_links}
          </ul>
        </div>
      </div>
    </Tile>
  )
}

export const primary_orphans_to_cards =  (frontmatter, po_data) => {
  let primary_orphans = [];

  if( po_data?.nodes?.length ){
    po_data.nodes.forEach( (orphan) => {
      let fn = orphan.slug.split("/")
      let orphan_url = frontmatter.section_url + fn[1]

      let newcard = (
        <Tile className={tile}>
          <div className="row no-gutters">
            <div className="col-12">
              <div className="card-body">
                <div className="card-title"><a href={orphan_url}>{orphan.frontmatter.header}</a></div>
                <p className="card-text">{orphan.excerpt}</p>
                <Button className={ctaButton}  url={orphan_url} size="compact"><T>guides-read-more-link</T>{<Arrow />}</Button>
              </div>
            </div>
          </div>
        </Tile>
      )

      primary_orphans.push(newcard)
    })
  }

  return primary_orphans
}

export default DisplayGuidesInPrimary
